// tslint:disable:completed-docs
import { NAMESPACE } from 'Constant';

/**
 * Actions
 */
export const FETCH_FLYOUT_CONTENT = `${NAMESPACE}/FETCH_FLYOUT_CONTENT` as const;
export const INJECT_CONTENT_SUCCESS = `${NAMESPACE}/INJECT_CONTENT_SUCCESS` as const;
export const INITIALIZE_TOBI = `${NAMESPACE}/INITIALIZE_TOBI` as const;
export const INITIALIZE_TOBI_SUCCESS = `${NAMESPACE}/INITIALIZE_TOBI_SUCCESS` as const;
export const SET_CONTACT_INFO = `${NAMESPACE}/SET_CONTACT_INFO` as const;
export const SAVE_ERROR = `${NAMESPACE}/SAVE_ERROR` as const;

/**
 * IDs
 */
export const BUTTON_TRIGGER_FLYOUT = 'contact-flyout__trigger';
export const CONTAINER_FLYOUT_OVERLAY = 'flyout-overlay';
export const RESET_VIEW_CONTAINER = 'reset-navigation-button-container';
export const MAIN_VIEW_CONTAINER = 'main-view-container';
export const OPENING_HOURS_CONTAINER = 'opening-hours-information';
export const OPENING_HOURS_ACCORDION_ITEM = 'opening-hours-accoridon-item';
export const OPENING_HOURS_ACCORDION_ITEM_ID = 'ws10-accordion-item__content-opening-hours-accoridon-item';
export const CUSTOMER_SERVICE_CONTAINER = 'customer-service';
export const COVER_ELEMENT_CONTAINER = 'cover-element';
export const TOBI_WEBCHAT_BUTTON_CONTAINER = 'tobi-webchat-button';
export const TOBI_WEBCHAT_CONTAINER = 'webchat-container';
export const LINK_TILES_CONTAINER = 'link-tiles-container';
export const HILFE_BUTTON_ID = 'hilfe-button';
export const HOTLINE_HEADLINE_ID = 'hotline-header';
export const HOTLINE_OPENING_HOURS_ID = 'default-opening-hours';

/**
 * Class names
 */
export const FLYOUT_CLASSNAME = 'contact-flyout';
export const FLYOUT_CONTENT_CLASSNAME = 'contact-flyout-content';
export const FLYOUT_VIEW_CLASSNAME = `${FLYOUT_CLASSNAME}__view`;
export const CONTAINER_HOTLINE_BUTTON = 'call-hotline-button-container';
export const FLYOUT_VIEW_CONTAINER_CLASS = 'contact-flyout__view';
export const FLYOUT_TOBI_VIEW_NO_PADDING = 'tobi-view-no-padding';
export const NAVIGATION_HOTLINE = 'mc-nav__meta-nav-link-contact-label';

/**
 * Tracking
 */
export const FLYOUT_TRACKING_EVENT = 'contact';
export const FLYOUT_TRACKING_ACTION = 'contact_action';
export const FLYOUT_TRACKING_ACTION_REVEAL = 'reveal';
export const FLYOUT_TRACKING_ACTION_ENGAGE = 'engage';
export const FLYOUT_TRACKING_SUBJECT = 'contact_subject';
export const FLYOUT_TRACKING_SUBJECT_CONTACT_SUPPORT = 'contact support';
export const FLYOUT_TRACKING_TOOL = 'contact_tool';
export const FLYOUT_TRACKING_TOOL_HOTLINE = 'hotline';
export const FLYOUT_TRACKING_TOOL_CHAT = 'chat';
export const FLYOUT_TRACKING_TOOL_ONLINE = 'online';
export const FLYOUT_TRACKING_TRIGGER = 'contact_trigger';
export const FLYOUT_TRACKING_TRIGGER_ICON = 'icon';
export const FLYOUT_TRACKING_TRIGGER_BUTTON = 'button';
export const FLYOUT_TRACKING_PHONE_NUMBER = 'contact_phone_number';
export const FLYOUT_TRACKING_AVAILABLE_TOOLS = 'contact_available_tools';
export const FLYOUT_TRACKING_AVAILABLE_TOOLS_DEFAULT = [
    FLYOUT_TRACKING_TOOL_HOTLINE,
    FLYOUT_TRACKING_TOOL_CHAT,
    FLYOUT_TRACKING_TOOL_ONLINE,
];
export const FLYOUT_TRACKING_VARIANT = 'contact_variant';
export const FLYOUT_TRACKING_VARIANT_REACTIVE = 'reactive';

/**
 * Focusable HTML elements
 */
export const FOCUSABLE_ELEMENTS = `
a[href],
button:not([disabled]),
textarea:not([disabled]),
input:not([disabled]),
select:not([disabled]),
[tabindex]:not([tabindex="-1"])`;

/**
 * TOBi resources
 */
export const URL_TOBI_JS = 'https://eweb.vfd2-testnet.de/simplicity/webchat/webchat.bundle.js';
export const URL_TOBI_CSS = 'https://eweb.vfd2-testnet.de/simplicity/webchat/webchat.bundle.css';
export const TOBI_BOT_NAME = 'TOBi';
export const TOBI_BOT_PATH = 'https://eweb.vfd2-testnet.de/gms/chat/tobiservice/';
export const TOBI_BOT_TOPIC = 'TOBiService';
export const TOBI_BOT_SUBLINE = 'Dein digitaler Assistent';
export const TOBI_BOT_ICON = 'https://eweb.vfd2-testnet.de/media/img/icons/mid-render/tobi-chat-header.svg';

/**
 *  Global events
 */
export const GLOBAL_FLYOUT_EVENT_SET_CONTACT = 'flyout:contactInfoSet';
export const GLOBAL_FLYOUT_EVENT = 'sails:flyout';
export const GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT = 'TOGGLE_FLYOUT';
export const GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT_SUCCESS = 'TOGGLE_FLYOUT_SUCCESS';
export const GLOBAL_FLYOUT_EVENT_SET_CONTACT_INFO = 'SET_CONTACT_INFO';

/**
 * Misc
 */
export const CALL_ICON = 'call';
export const BEACON_PUBLISH = 'publish';
export const KEYDOWN_EVENT = 'keydown';
export const TAB_EVENT = 'Tab';
